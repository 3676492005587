import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    country: sessionStorage.getItem("country") || null,
    language: sessionStorage.getItem("language") || "en",
    token: sessionStorage.getItem("token") || null,
    user: JSON.parse(sessionStorage.getItem("user")) || null,
    organization: JSON.parse(sessionStorage.getItem("organization")) || null,
    generalInfo: JSON.parse(sessionStorage.getItem("generalInfo")) || null,
    redirectTo: sessionStorage.getItem("redirectTo") || null,
  },
  reducers: {
    setRedirectTo: (state, action) => {
      const redirectToData = {
        type: action.payload.type,
        branchId: action.payload.branchId,
      };
      state.redirectTo = redirectToData;
      const redirectToJson = JSON.stringify(redirectToData);
      sessionStorage.setItem("redirectTo", redirectToJson);
    },
    saveCountry: (state, action) => {
      state.country = action.payload;
      sessionStorage.setItem("country", action.payload);
    },
    saveLanguage: (state, action) => {
      if (state.language !== action.payload.language) {
        state.language = action.payload.language;
        sessionStorage.setItem("language", action.payload.language);
      }
    },
    login: (state, action) => {
      const userData = {
        aId: action.payload.user.aId,
        id: action.payload.user._id,
        firstName: action.payload.user.firstName,
        lastName: action.payload.user.lastName,
        profilePictureUrl: action.payload.user.profilePictureUrl,
        phoneNumber: action.payload.user.phoneNumber,
        countryCode: action.payload.user.countryCode,
        isVerified: action.payload.user.isVerified,
        email: action.payload.user.email,
      };
      state.user = userData;
      const userJson = JSON.stringify(userData);
      sessionStorage.setItem("user", userJson);

      state.token = action.payload.token;
      sessionStorage.setItem("token", action.payload.token);
    },
    logout: (state) => {
      state.country = null;
      state.user = null;
      state.token = null;
      state.organization = null;
      sessionStorage.removeItem("country");
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("organization");
    },
    saveBranchData: (state, action) => {
      const orgData = {
        providerId: action.payload.providerId,
        organizationLogoUrl: action.payload.organizationLogoUrl,
        subscriptionId: action.payload.subscriptionId,
        name: action.payload.name,
        branchId: action.payload.branchId,
        branchType: action.payload.branchType,
        branchName: action.payload.branchName,
        branchLogoUrl: action.payload.branchLogoUrl,
        country: action.payload.country,
        isFreeTrial: action.payload.isFreeTrial,
        packageKey: action.payload.packageKey,
        role: action.payload.role,
        position: action.payload.position,
        type: action.payload.type,
      };
      state.organization = orgData;
      const orgJson = JSON.stringify(orgData);
      sessionStorage.setItem("organization", orgJson);
    },
    saveAyamedicaInfo: (state, action) => {
      const infoKeys = {
        phoneNumbers: action.payload.data.phoneNumber,
        emailAddresses: action.payload.data.emailAddress,
        privacyPolicy: action.payload.data.privacyPolicy,
        socialMediaLinks: action.payload.data.socialMediaLink,
        termsAndConditions: action.payload.data.termsAndConditions,
        profilePictureBaseURL: action.payload.data.profilePictureBaseURL,
        medicalRecordPictureBaseURL:
          action.payload.data.medicalRecordPictureBaseURL,
        providerPictureBaseURL: action.payload.data.providerPictureBaseURL,
        monolithURL: action.payload.data.monolithURL,
        usersSubgraphURL: action.payload.data.usersURL,
        ayamedicaSSOURL: action.payload.data.SSOURL,
        ayamedicaDashboardURL: action.payload.data.ayamedicaDashboardURL,
        ayamedicaSchoolURL: action.payload.data.ayamedicaSchoolURL,
      };
      state.generalInfo = infoKeys;
      const infoKeysJson = JSON.stringify(infoKeys);
      sessionStorage.setItem("generalInfo", infoKeysJson);
    },
  },
});

export const authReducer = authSlice.reducer;
export const {
  logout,
  login,
  saveAyamedicaInfo,
  saveFirebaseToken,
  saveLanguage,
} = authSlice.actions;
